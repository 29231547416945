import { useCallback, useEffect, useMemo, useRef } from 'react';
// import { interval } from 'd3-timer';
import {
  getDocumentRotationInfo,
  getScrollContainer,
  getScrollInfo,
  getZoomScaleInfo,
  makeMarkDisappear,
} from '../utils';
import { useSelector } from 'react-redux';
import {
  selectCurrentCase,
  selectCurrentFolder,
  selectCurrentSelectedFileMetaData,
  selectIsGlobalPageLookup,
  selectMarkBasedScrolling,
  selectMatchedUrl,
  selectPresentQuery,
  selectQuery,
} from 'common/selectors';
import {
  HIGHLIGHTSDISAPPEAR,
  SCROLL_MARK_TYPE,
  PRESENT_COLOR,
  PAGENUMBER_MARK_TYPE,
} from '../constants';
import { MouseTool, MouseTools, Mark } from '@prizmdoc/viewer-core';
import { useStartPresenting } from 'features/viewing/redux/startPresenting';
import { useSendMessage } from 'features/viewing/redux/sendMessage';
import history from 'common/history';
import { makeCancelable } from 'utils/promises';
import { useLeaveAGroup } from 'features/viewing/redux/leaveAGroup';
import logger from 'utils/logger';

export const useSendMessages = ({
  passViewingSessionIdToReceivers,
  firstParentTriaBundleFileDetails,
  fileId,
  options,
  viewingFilePrivate,
  viewerControlRef,
  setCurrentMouseTool,
  selectedMouseTool,
  deleteMarkToBeCreated,
}: any) => {
  const { sendMessage: sendMessageApi } = useSendMessage();
  const { leaveAGroup } = useLeaveAGroup();
  const { startPresenting } = useStartPresenting();

  const present = useSelector(selectPresentQuery);
  const currentCase = useSelector(selectCurrentCase);
  const currentFolder = useSelector(selectCurrentFolder);
  const currentFileMetaData = useSelector(selectCurrentSelectedFileMetaData) as any;
  const isGlobalPageLookup = useSelector(selectIsGlobalPageLookup);
  const queryParam = useSelector(selectQuery);
  const currentUrl = useSelector(selectMatchedUrl);
  const markBasedScrolling = useSelector(selectMarkBasedScrolling);

  const showGlobalPaging = currentFolder && currentFolder.globalPaging;
  const isPresentModePage = currentUrl.includes(`${currentCase.id}/present-`);
  const isPresenting = !!present;

  const leaveGroupSafeRef = useRef<any>(null);
  const startPresentingSafeRef = useRef<any>(null);
  const addCenterHelperMarkSafeRef = useRef<any>(null);

  // const timerRef = useRef<any>(null);
  const markToDisappear = useRef<any>(null);

  const sendMessage = useCallback(
    (message: any) => {
      if (!isPresenting) return;
      const fullMessage = {
        fileId,
        target: 'present',
        room: present,
        message: {
          ...getScrollInfo(getScrollContainer(viewerControlRef.current)),
          ...getZoomScaleInfo(viewerControlRef.current),
          ...getDocumentRotationInfo(viewerControlRef.current),
          ...message,
        },
      };

      sendMessageApi({ message: fullMessage });
    },
    [isPresenting, fileId, present, sendMessageApi, viewerControlRef],
  );

  const switchDocument = useCallback(
    (doc: any) => {
      const containsFolderId = Object.keys(queryParam).includes('folderId');
      const folderIdParamStr = `folderId=${doc.folderId || queryParam.folderId}`;
      const containsGpParam = Object.keys(queryParam).includes('startPage');
      const gpParamStr = `startPage=${queryParam.startPage}&tab=${doc.tab || queryParam.tab}&pageCount=${doc.pageCount || queryParam.pageCount}&globalPagePrefix=${doc.globalPagePrefix || queryParam.globalPagePrefix}`;
      const viewMode = `view=share`;

      // Build the query string conditionally
      const queryParts: string[] = [];

      if (containsFolderId) {
        queryParts.push(folderIdParamStr);
      }

      queryParts.push('1');

      if (containsGpParam) {
        queryParts.push(gpParamStr);
      }

      if (viewMode) {
        queryParts.push(viewMode);
      }

      if (present) {
        queryParts.push(`present=${present || 'public'}`);
      }

      const queryString = queryParts.length > 0 ? `?${queryParts.join('&')}` : '';

      history.push(
        history.location.pathname.replace(/\/files\/[^/]+/, `/files/${doc.id}`) + queryString,
      );
    },
    [queryParam, present, history],
  );

  const getFullMessageForStartPresenting = useCallback(() => {
    const currentFileDetails =
      showGlobalPaging ||
      isGlobalPageLookup ||
      (firstParentTriaBundleFileDetails &&
        Object.keys(firstParentTriaBundleFileDetails).length > 0) ||
      (isPresentModePage &&
        currentFileMetaData &&
        Object.keys(currentFileMetaData).includes('folderId'))
        ? {
            ...currentFileMetaData,
            ...(Object.keys(firstParentTriaBundleFileDetails).length > 0 &&
              firstParentTriaBundleFileDetails),
          }
        : Object.keys(queryParam).includes('startPage')
          ? queryParam
          : null;

    if (!viewerControlRef.current) return {};

    const rest = {
      ...(passViewingSessionIdToReceivers && {
        viewingSessionId: options.documentID,
        prizmApiUrl: options.imageHandlerUrl,
        viewingFilePrivate: viewingFilePrivate,
      }),
      fileId,
      fileType: 'doc',
      showGlobalPaging:
        showGlobalPaging ||
        Object.keys(queryParam).includes('startPage') ||
        isGlobalPageLookup ||
        (firstParentTriaBundleFileDetails &&
          Object.keys(firstParentTriaBundleFileDetails).length > 0) ||
        (isPresentModePage &&
          currentFileMetaData &&
          Object.keys(currentFileMetaData).includes('folderId')),
      ...(currentFileDetails && {
        currentFileDetails: {
          globalPagePrefix: currentFileDetails.globalPagePrefix,
          startPage: currentFileDetails.startPage,
          pageCount: currentFileDetails.pageCount,
          tab: currentFileDetails.tab,
        },
      }),
      ...getScrollInfo(getScrollContainer(viewerControlRef.current)),
      ...getZoomScaleInfo(viewerControlRef.current),
      ...getDocumentRotationInfo(viewerControlRef.current),
    };

    return { markObjects: [], ...rest };
  }, [
    showGlobalPaging,
    isGlobalPageLookup,
    firstParentTriaBundleFileDetails,
    isPresentModePage,
    currentFileMetaData,
    queryParam,
    viewerControlRef,
    passViewingSessionIdToReceivers,
    options,
    fileId,
    viewingFilePrivate,
  ]);

  const addCenterHelperMark = useCallback(
    (otherChng?: any, offset = 0) => {
      let lastMarkHelper: any;

      const markCreated = ({ mark }: any) => {
        const scrollMark = mark.type === SCROLL_MARK_TYPE;
        if (scrollMark) lastMarkHelper = mark;
      };

      try {
        const myMouseToolName = 'scrollHelperTool';
        const myMouseTool = MouseTools.createMouseTool(
          myMouseToolName,
          MouseTool.Type.PlaceSignature,
        );
        myMouseTool.getTemplateMark().setSignature({
          // type: SCROLL_MARK_TYPE,
          text: '............................................................................................',
          fontName: 'Aerial',
          // path: 'M0,0',
          // color: '#FFFFFF', NOT WORKING RETARDED ACUSOFT
        });
        myMouseTool.getTemplateMark().interactionMode = Mark.InteractionMode.SelectionDisabled;

        const clientX =
          getScrollContainer(viewerControlRef.current)?.getBoundingClientRect().x +
          getScrollContainer(viewerControlRef.current)?.clientWidth / 2;
        const clientY =
          getScrollContainer(viewerControlRef.current)?.getBoundingClientRect().y +
          getScrollContainer(viewerControlRef.current)?.clientHeight / 2;

        const createMarkHelper = () => {
          viewerControlRef.current?.setCurrentMouseTool(myMouseToolName);

          getScrollContainer(viewerControlRef.current)?.dispatchEvent(
            new MouseEvent('mousedown', {
              clientX,
              clientY: clientY + offset,
              view: window,
            }),
          );
          getScrollContainer(viewerControlRef.current)?.dispatchEvent(
            new MouseEvent('mouseup', {
              clientX,
              clientY: clientY + offset,
              view: window,
            }),
          );
          setCurrentMouseTool(selectedMouseTool);
        };

        viewerControlRef.current?.on('MarkCreated', markCreated);

        createMarkHelper();
      } catch {
        lastMarkHelper = 'error';
      } finally {
        setTimeout(() => {
          viewerControlRef.current?.off('MarkCreated', markCreated);
          if (!lastMarkHelper) {
            // DO ANOTHER ONE IF IN THE MIDDLE OF THE SCREEN
            addCenterHelperMark(otherChng, 30);
          }
        }, 250); // needed timeout so set state get's set
      }
    },
    [selectedMouseTool, setCurrentMouseTool, viewerControlRef],
  );

  const sendFullMessage = () => {
    sendMessage(getFullMessageForStartPresenting());
  };

  const updateScrollPositionForReceivers = useMemo(
    () => (otherChng?: any) => {
      if (!viewerControlRef.current || !isPresenting) return;

      if (!markBasedScrolling) {
        sendMessage({
          ...otherChng,
          ...getScrollInfo(getScrollContainer(viewerControlRef.current)),
        });
      } else {
        addCenterHelperMarkSafeRef.current = makeCancelable(
          new Promise<void>((r) => {
            addCenterHelperMark(otherChng);
            r();
          }),
        );
        addCenterHelperMarkSafeRef.current.promise.then(() => {});
      }
    },
    [addCenterHelperMark, isPresenting, markBasedScrolling, sendMessage, viewerControlRef],
  );

  const deleteMarkToDisappear = useCallback(() => {
    if (markToDisappear.current) {
      viewerControlRef.current?.deleteMarks([markToDisappear.current]);
      markToDisappear.current = null;
    }
  }, [viewerControlRef]);

  const deletePresentMarks = useCallback(() => {
    try {
      deleteMarkToDisappear();
      const presentMarks = viewerControlRef.current
        ?.getAllMarks()
        .filter((x: any) => x.fillColor === PRESENT_COLOR);
      if (presentMarks?.length > 0) {
        viewerControlRef.current?.deleteMarks(presentMarks);
      }
    } catch (e) {
      logger.ERROR(e);
    }
  }, [deleteMarkToDisappear, viewerControlRef]);

  const startStopPresentHandler = useMemo(
    () => (mode?: 'private' | 'public', forcePresent?: boolean) => {
      const containsGpParam = Object.keys(queryParam).includes('startPage');
      const containsFolderId = Object.keys(queryParam).includes('folderId');
      const gpParamStr = `goToPage=${queryParam.goToPage || '1'}&startPage=${queryParam.startPage}&tab=${queryParam.tab}&pageCount=${queryParam.pageCount}&globalPagePrefix=${queryParam.globalPagePrefix}`;
      const folderIdParamStr = `folderId=${queryParam.folderId}`;
      const viewMode = ``;
      deleteMarkToBeCreated();
      if (
        isPresenting &&
        (!forcePresent || (present === 'public' && currentFileMetaData.private))
      ) {
        leaveGroupSafeRef.current = makeCancelable(
          new Promise((r: any) => leaveAGroup('present').then(() => r())),
        );
        leaveGroupSafeRef.current.promise.then(() => {
          history.push(
            history.location.pathname.replace(/\?present.*/, ``) +
              `${containsFolderId ? `?${folderIdParamStr}` : ''}${
                containsFolderId && containsGpParam ? '&' : ''
              }${containsGpParam ? `${gpParamStr}` : ''}` +
              `${viewMode ? '&' : ''}${viewMode}`,
          );
          // timerRef.current?.stop();
          // TO-DO this might require useEffect to execute after present was set
          deletePresentMarks();
          setCurrentMouseTool(selectedMouseTool, false);
          // --------------------------------------------
        });
      } else {
        startPresentingSafeRef.current = makeCancelable(
          new Promise((r: any) => {
            startPresenting({
              hearingRoomMode: mode,
              message: getFullMessageForStartPresenting(),
            }).then(() => {
              if (markBasedScrolling) addCenterHelperMark();
              // timerRef.current?.stop();
              // timerRef.current = interval(() => updateScrollPositionForReceivers(), 5000);
              r();
            });
          }),
        );

        startPresentingSafeRef.current.promise.then(() => {
          setCurrentMouseTool(selectedMouseTool, true);
          history.push(
            history.location.pathname.replace(/\?present.*/, '') +
              `?${containsFolderId ? `${folderIdParamStr}&` : ''}` +
              `${containsGpParam ? `${gpParamStr}&` : ''}` +
              `present=${mode}`,
          );
        });
      }
    },
    [
      addCenterHelperMark,
      currentFileMetaData.private,
      deleteMarkToBeCreated,
      deletePresentMarks,
      getFullMessageForStartPresenting,
      isPresenting,
      leaveAGroup,
      markBasedScrolling,
      present,
      queryParam,
      selectedMouseTool,
      setCurrentMouseTool,
      startPresenting,
      // updateScrollPositionForReceivers,
    ],
  );
  const startStopPresentHandlerWPresentView = useMemo(
    () => (mode?: 'private' | 'public', forcePresent?: boolean) => {
      const containsGpParam = Object.keys(queryParam).includes('startPage');
      const containsFolderId = Object.keys(queryParam).includes('folderId');
      const gpParamStr = `goToPage=${queryParam.goToPage || '1'}&startPage=${queryParam.startPage}&tab=${queryParam.tab}&pageCount=${queryParam.pageCount}&globalPagePrefix=${queryParam.globalPagePrefix}`;
      const folderIdParamStr = `folderId=${queryParam.folderId}`;
      const viewMode = `view=share`;
      deleteMarkToBeCreated();
      if (
        isPresenting &&
        (!forcePresent || (present === 'public' && currentFileMetaData.private))
      ) {
        leaveGroupSafeRef.current = makeCancelable(
          new Promise((r: any) => leaveAGroup('present').then(() => r())),
        );
        leaveGroupSafeRef.current.promise.then(() => {
          history.push(
            history.location.pathname.replace(/\?present.*/, ``) +
              `${containsFolderId ? `?${folderIdParamStr}` : ''}${
                containsFolderId && containsGpParam ? '&' : ''
              }${containsGpParam ? `${gpParamStr}` : ''}` +
              `${viewMode ? '&' : ''}${viewMode}`,
          );
          // timerRef.current?.stop();
          // TO-DO this might require useEffect to execute after present was set
          deletePresentMarks();
          setCurrentMouseTool(selectedMouseTool, false);
          // --------------------------------------------
        });
      } else {
        startPresentingSafeRef.current = makeCancelable(
          new Promise((r: any) => {
            startPresenting({
              hearingRoomMode: mode,
              message: getFullMessageForStartPresenting(),
            }).then(() => {
              if (markBasedScrolling) addCenterHelperMark();
              // timerRef.current?.stop();
              // timerRef.current = interval(() => updateScrollPositionForReceivers(), 5000);
              r();
            });
          }),
        );

        startPresentingSafeRef.current.promise.then(() => {
          setCurrentMouseTool(selectedMouseTool, true);
          history.push(
            history.location.pathname.replace(/\?present.*/, '') +
              `?${containsFolderId ? `${folderIdParamStr}&` : ''}` +
              `${containsGpParam ? `${gpParamStr}&` : ''}` +
              `view=share&` +
              `present=${mode}`,
          );
        });
      }
    },
    [
      addCenterHelperMark,
      currentFileMetaData.private,
      deleteMarkToBeCreated,
      deletePresentMarks,
      getFullMessageForStartPresenting,
      isPresenting,
      leaveAGroup,
      markBasedScrolling,
      present,
      queryParam,
      selectedMouseTool,
      setCurrentMouseTool,
      startPresenting,
      // updateScrollPositionForReceivers,
    ],
  );
  const MarkCreated = useCallback(
    ({ mark }: any) => {
      if (isPresenting) {
        mark.interactionMode = Mark.InteractionMode.SelectionDisabled;
        mark.setData('visible', 'true');
        try {
          viewerControlRef.current?.serializeMarks([mark]).then((markObjects: any) => {
            const scrollMark = mark.type === SCROLL_MARK_TYPE;
            const pageNumberMark = mark.type === PAGENUMBER_MARK_TYPE;

            if (pageNumberMark) return;

            sendMessage({ markObjects });

            if (scrollMark) {
              viewerControlRef.current?.deleteMarks([mark]);
              return;
            }

            if (HIGHLIGHTSDISAPPEAR && markToDisappear.current) {
              const m = markToDisappear.current;
              makeMarkDisappear(
                m,
                () => viewerControlRef.current?.deleteMarks([m]),
                () => !isPresenting,
              );
            }

            if (markToDisappear.current !== mark) markToDisappear.current = mark;
          });
        } catch (e) {
          logger.ERROR(e);
        }
      }
    },
    [isPresenting, sendMessage, viewerControlRef],
  );

  const MarkClickedHandler = useCallback(
    ({ mark }: any) => {
      if (mark && isPresenting) {
        mark.interactionMode = Mark.InteractionMode.SelectionDisabled;
        mark.visible = false;
        mark.setData('visible', 'false');
        if (HIGHLIGHTSDISAPPEAR) {
          viewerControlRef.current?.serializeMarks([mark]).then((markObjects: any) => {
            sendMessage({ markObjects });
            viewerControlRef.current?.deleteMarks([mark]);
            markToDisappear.current = null;
          });
        }
      }
    },
    [isPresenting, sendMessage, viewerControlRef],
  );

  useEffect(() => {
    viewerControlRef.current?.on('MarkCreated', MarkCreated);
    viewerControlRef.current?.on('Click', MarkClickedHandler);

    const vcr = viewerControlRef.current;
    return () => {
      vcr?.off('MarkCreated', MarkCreated);
      vcr?.off('Click', MarkClickedHandler);
    };
  }, [MarkClickedHandler, MarkCreated, viewerControlRef]);

  useEffect(() => {
    // const tr = timerRef.current;

    return () => {
      startPresentingSafeRef.current?.cancel();
      leaveGroupSafeRef.current?.cancel();
      addCenterHelperMarkSafeRef.current?.cancel();
      // tr?.stop();
    };
  }, []);

  return {
    startStopPresentHandler,
    startStopPresentHandlerWPresentView,
    updateScrollPositionForReceivers,
    sendFullMessage,
    switchDocument,
  };
};
